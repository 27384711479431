import React from 'react';
import { NavLink } from 'react-router-dom';

import './contact.style.scss';
import { withRouter } from '../../utils/withRouter';
const Contact = (props) => {

  return (
    <section className="contact-section py-0">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-7 h-100 vertical-align-center">
            <div className="my-5 my-lg-0 d-flex flex-column justify-content-center justify-content-lg-start">
              <h1 className="mx-auto ml-lg-0">{props.title}</h1>
              <NavLink to={props.link} className="btn btn-outline btn-xl mt-3 mx-auto ml-lg-0">{props.buttonTitle}</NavLink>
            </div>
          </div>
          <div className="col-lg-5 h-100">
            <div className={`image-container vertical-align-center h-100 ${props.shifted ? 'shifted-up' : ''}`}>
              <img src={props.image} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(Contact);
