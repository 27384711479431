import React from 'react';
import { useNavigate } from 'react-router-dom';

import { withRouter } from '../../utils/withRouter';
import ReactMarkdown from 'react-markdown';
import Header from '../../components/Header/Header.component';
import AboutCustomer from '../../components/AboutCustomer/AboutCustomer.component';
import Testimonial from '../../components/Testimonial/Testimonial.component';
import AppDownload from '../../components/AppDownload/AppDownload.component';
import Contact from '../../components/Contact/Contact.component';
import GetQuote from '../../components/GetQuote/GetQuote.component';
import Accordion from '../../components/Accordion';
import PersonalSubNav from '../../components/PersonalSubNav';
import SenderHowItWorks from '../../components/SenderHowItWorks';
import { I18nContext } from '../../contexts/i18n';
import useScrollToTop from '../../utils/useScrollToTop';
import personImage from '../../assets/images/sender-person.png';
import documentsImage from '../../assets/images/documents.png';
import constants from '../../Constants';

const Personal = () => {
  const { state } = React.useContext(I18nContext);
  const i18n = state.i18n;
  const navigate = useNavigate();

  useScrollToTop();

  return (
    <>
      <Header
        tag={i18n.t('sender.personal.title')}
        title={i18n.t('sender.personal.headerTitle')}
        body={i18n.t('sender.personal.headerBody')}
      />
      <GetQuote
        title={i18n.t('quote.title')}
        buttonTitle={i18n.t('quote.header')}
        link='/quote'
        image={documentsImage}
        category={i18n.t('quote.form.category.documents')}
        size={i18n.t('quote.form.size.medium')}
        minCost={1500}
        maxCost={3500}
      />
      <section>
        <div className="container">
          <div className="section-heading text-center ml-auto mr-auto">
            <h2>{i18n.t('sender.personal.categories')}</h2>
            <hr />
          </div>
          <PersonalSubNav type='box' classNames='mt-4' />
        </div>
      </section>
      <AboutCustomer
        title={i18n.t('sender.personal.whySend.title')}
        texts={[
          { title: i18n.t('sender.personal.whySend.saveTime.title'), text: i18n.t('sender.personal.whySend.saveTime.text') },
          { title: i18n.t('sender.personal.whySend.faster.title'), text: i18n.t('sender.personal.whySend.faster.text') },
          { title: i18n.t('sender.personal.whySend.cheaper.title'), text: i18n.t('sender.personal.whySend.cheaper.text') }
        ]}
      />
      <Testimonial senderOnly />
      <AppDownload />
      <SenderHowItWorks />
      <section>
        <div className="container">
          <div className="section-heading text-center ml-auto mr-auto mb-5">
            <h2>{i18n.t('general.faqs.title')}</h2>
            <hr />
          </div>
          <Accordion title={i18n.t('sender.faqs.reimburseLostItems.question')}>
            <div>
              <p>{i18n.t('sender.faqs.reimburseLostItems.answer1')}</p>
              <ReactMarkdown>{i18n.t('sender.faqs.reimburseLostItems.answer2')}</ReactMarkdown>
              <p>{i18n.t('sender.faqs.reimburseLostItems.answer3')}</p>
              <p>{i18n.t('sender.faqs.reimburseLostItems.answer4')}</p>
              <ReactMarkdown>{i18n.t('sender.faqs.reimburseLostItems.answer5')}</ReactMarkdown>
              <ReactMarkdown>
                {i18n.t(
                  'sender.faqs.reimburseLostItems.answer6',
                  { phone: constants.NGINA_SUPPORT_PHONE_NUMBER, email: constants.NGINA_SUPPORT_EMAIL }
                )}
              </ReactMarkdown>
            </div>
          </Accordion>
          <Accordion title={i18n.t('sender.faqs.fileClaim.question')}>
            <div>
              <p>{i18n.t('sender.faqs.fileClaim.answer1.text')}</p>
              <ul>
                <li>{i18n.t('sender.faqs.fileClaim.answer1.point1')}</li>
                <li>{i18n.t('sender.faqs.fileClaim.answer1.point2')}</li>
                <li>{i18n.t('sender.faqs.fileClaim.answer1.point3')}</li>
                <li>{i18n.t('sender.faqs.fileClaim.answer1.point4')}</li>
                <li><ReactMarkdown renderers={{ paragraph: 'span' }}>{i18n.t('sender.faqs.fileClaim.answer1.point5')}</ReactMarkdown></li>
              </ul>
              <p>{i18n.t('sender.faqs.fileClaim.answer2')}</p>
              <p>{i18n.t('sender.faqs.fileClaim.answer3')}</p>
            </div>
          </Accordion>
          <Accordion title={i18n.t('sender.faqs.whenCharged.question')}>
            <div>
              <p>{i18n.t('sender.faqs.whenCharged.answer1')}</p>
              <p>{i18n.t('sender.faqs.whenCharged.answer2')}</p>
            </div>
          </Accordion>

          <div className="text-center">
            <button
              className="btn btn-primary btn-xl mt-5"
              onClick={() => navigate('/faqs?user=sender')}
            >
              {i18n.t('general.faqs.seeAll')}
            </button>
          </div>
        </div>
      </section>
      <Contact
        title={i18n.t('sender.personal.footerTitle')}
        buttonTitle={i18n.t('general.getStarted')}
        link='/download/android'
        image={personImage}
        shifted={true}
      />
    </>
  );
};

export default withRouter(Personal);
