import React from 'react';

import { withRouter } from '../../utils/withRouter';
import SmallBusinessSubNav from '../../components/SmallBusinessSubNav';
import AppDownload from '../../components/AppDownload/AppDownload.component';
import { I18nContext } from '../../contexts/i18n';
import useScrollToTop from '../../utils/useScrollToTop';
import automotive from  '../../assets/images/automotive.jpg';

const Automotive = (props) => {
  const { state } = React.useContext(I18nContext);
  const i18n = state.i18n;

  useScrollToTop();

  return (
    <>
      <SmallBusinessSubNav classNames='mt-5 pt-2' />
      <section>
        <div className="container">
          <div className="row section-heading">
            <div className="col-lg-8 my-auto">
              <div className="tag">{i18n.t('sender.smallBusiness.automotive.label')}</div>
              <h1>{i18n.t('sender.smallBusiness.automotive.headerTitle')}</h1>
              <a href="#download" className="btn btn-primary btn-xl js-scroll-trigger">{i18n.t('general.joinAndSend')}</a>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-grey">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img src={automotive} className="img-fluid w-100 mb-3" alt="" />
              <h5 className="font-weight-bold">{i18n.t('sender.smallBusiness.automotive.heading1')}</h5>
              <p>{i18n.t('sender.smallBusiness.automotive.text11')}</p>
              <p>{i18n.t('sender.smallBusiness.automotive.text12')}</p>
            </div>
            <div className="col-md-6">
              <h5 className="font-weight-bold">{i18n.t('sender.smallBusiness.automotive.heading2')}</h5>
              <p>{i18n.t('sender.smallBusiness.automotive.text2')}</p>
              <h5 className="font-weight-bold">{i18n.t('sender.smallBusiness.automotive.heading3')}</h5>
              <p>{i18n.t('sender.smallBusiness.automotive.text3')}</p>
              <h5 className="font-weight-bold">{i18n.t('sender.smallBusiness.automotive.heading4')}</h5>
              <p>{i18n.t('sender.smallBusiness.automotive.text4')}</p>
            </div>
          </div>
        </div>
      </section>
      <AppDownload />
    </>
  );
};

export default withRouter(Automotive);
