import React from 'react';
import ReactMarkdown from 'react-markdown';

import { withRouter } from '../../utils/withRouter';
import SmallBusinessSubNav from '../../components/SmallBusinessSubNav';
import AppDownload from '../../components/AppDownload/AppDownload.component';
import { I18nContext } from '../../contexts/i18n';
import useScrollToTop from '../../utils/useScrollToTop';
import ecommerce from  '../../assets/images/e-commerce.jpg';

const Ecommerce = (props) => {
  const { state } = React.useContext(I18nContext);
  const i18n = state.i18n;

  useScrollToTop();

  return (
    <>
      <SmallBusinessSubNav classNames='mt-5 pt-2' />
      <section>
        <div className="container">
          <div className="row section-heading">
            <div className="col-lg-8 my-auto">
              <div className="tag">{i18n.t('sender.smallBusiness.ecommerce.label')}</div>
              <h1>{i18n.t('sender.smallBusiness.ecommerce.headerTitle')}</h1>
              <a href="#download" className="btn btn-primary btn-xl js-scroll-trigger">{i18n.t('general.joinAndSend')}</a>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-grey">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img src={ecommerce} className="img-fluid w-100 mb-3" alt="" />
              <h5 className="font-weight-bold">{i18n.t('sender.smallBusiness.ecommerce.heading1')}</h5>
              <p>{i18n.t('sender.smallBusiness.ecommerce.text1')}</p>
              <h5 className="font-weight-bold">{i18n.t('sender.smallBusiness.ecommerce.heading2')}</h5>
              <p>{i18n.t('sender.smallBusiness.ecommerce.text2')}</p>
              <h5 className="font-weight-bold">{i18n.t('sender.smallBusiness.ecommerce.heading3')}</h5>
              <p>{i18n.t('sender.smallBusiness.ecommerce.text3')}</p>
              <h5 className="font-weight-bold">{i18n.t('sender.smallBusiness.ecommerce.heading4')}</h5>
              <p>{i18n.t('sender.smallBusiness.ecommerce.text4')}</p>
            </div>
            <div className="col-md-6">
              <h5 className="font-weight-bold">{i18n.t('sender.smallBusiness.ecommerce.heading5')}</h5>
              <p>{i18n.t('sender.smallBusiness.ecommerce.text5')}</p>
              <h5 className="font-weight-bold">{i18n.t('sender.smallBusiness.ecommerce.heading6')}</h5>
              <p><ReactMarkdown source={i18n.t('sender.smallBusiness.ecommerce.text61')} /></p>
              <p><ReactMarkdown source={i18n.t('sender.smallBusiness.ecommerce.text62')} /></p>
              <ul>
                <li><ReactMarkdown renderers={{ paragraph: 'span' }} source={i18n.t('sender.smallBusiness.ecommerce.point61')} /></li>
                <li><ReactMarkdown renderers={{ paragraph: 'span' }} source={i18n.t('sender.smallBusiness.ecommerce.point62')} /></li>
                <li><ReactMarkdown renderers={{ paragraph: 'span' }} source={i18n.t('sender.smallBusiness.ecommerce.point63')} /></li>
              </ul>
              <h5 className="font-weight-bold">{i18n.t('sender.smallBusiness.ecommerce.heading7')}</h5>
              <p><ReactMarkdown source={i18n.t('sender.smallBusiness.ecommerce.text7')} /></p>
            </div>
          </div>
        </div>
      </section>
      <AppDownload />
    </>
  );
};

export default withRouter(Ecommerce);
