import React from 'react';
import { useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import { withRouter } from '../utils/withRouter';
import Accordion from '../components/Accordion';
import { I18nContext } from '../contexts/i18n';
import User from '../services/user';
import useScrollToTop from '../utils/useScrollToTop';
import constants from '../Constants';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Faqs = (props) => {
  const { state } = React.useContext(I18nContext);
  const i18n = state.i18n;
  const query = useQuery();
  const [user, setUser] = React.useState(query.get('user') || User.DRIVER);

  const isActiveUser = (selectedUser) => {
    return selectedUser === user ? 'active' : '';
  }

  const renderContent = () => {
    if (user === User.DRIVER) {
      return (
        <>
          <Accordion title={i18n.t('drivers.faqs.whatINeedToBeADriver.question')}>
            <p>{i18n.t('drivers.faqs.whatINeedToBeADriver.answer1')}</p>
          </Accordion>
          <Accordion title={i18n.t('drivers.faqs.howMuchPay.question')}>
            <p>{i18n.t('drivers.faqs.howMuchPay.answer1')}</p>
          </Accordion>
          <Accordion title={i18n.t('drivers.faqs.whyBackgroundChecks.question')}>
            <div>
              <p>{i18n.t('drivers.faqs.whyBackgroundChecks.answer1')}</p>
              <p>{i18n.t('drivers.faqs.whyBackgroundChecks.answer2')}</p>
              <p>{i18n.t('drivers.faqs.whyBackgroundChecks.answer3')}</p>
            </div>
          </Accordion>
          <Accordion title={i18n.t('drivers.faqs.backgroundCheckProcess.question')}>
            <div>
              <p>{i18n.t('drivers.faqs.backgroundCheckProcess.answer1')}</p>
              <p>{i18n.t('drivers.faqs.backgroundCheckProcess.answer2.text')}</p>
              <ul>
                <li>{i18n.t('drivers.faqs.backgroundCheckProcess.answer2.point1')}</li>
                <li>{i18n.t('drivers.faqs.backgroundCheckProcess.answer2.point2')}</li>
                <li>{i18n.t('drivers.faqs.backgroundCheckProcess.answer2.point3')}</li>
                <li>{i18n.t('drivers.faqs.backgroundCheckProcess.answer2.point4')}</li>
                <li>{i18n.t('drivers.faqs.backgroundCheckProcess.answer2.point5')}</li>
                <li>{i18n.t('drivers.faqs.backgroundCheckProcess.answer2.point6')}</li>
                <li>{i18n.t('drivers.faqs.backgroundCheckProcess.answer2.point7')}</li>
                <li>{i18n.t('drivers.faqs.backgroundCheckProcess.answer2.point8')}</li>
              </ul>
              <p>{i18n.t('drivers.faqs.backgroundCheckProcess.answer3')}</p>
            </div>
          </Accordion>
          <Accordion title={i18n.t('drivers.faqs.disputeBackgroundCheck.question')}>
            <div>
              <p>{i18n.t('drivers.faqs.disputeBackgroundCheck.answer1')}</p>
              <p>{i18n.t('drivers.faqs.disputeBackgroundCheck.answer2')}</p>
            </div>
          </Accordion>
          <Accordion title={i18n.t('drivers.faqs.payBackgroundCheck.question')}>
            <div>
              <p>{i18n.t('drivers.faqs.payBackgroundCheck.answer1')}</p>
            </div>
          </Accordion>

          <Accordion title={i18n.t('drivers.faqs.howToDrive.question')}>
            <div>
              <ReactMarkdown>{i18n.t('drivers.faqs.howToDrive.answer1')}</ReactMarkdown>
            </div>
          </Accordion>
          <Accordion title={i18n.t('general.faqs.howDriversMatched.question')}>
            <div>
              <p>{i18n.t('general.faqs.howDriversMatched.answer1')}</p>
              <p>{i18n.t('general.faqs.howDriversMatched.answer2')}</p>
            </div>
          </Accordion>
          <Accordion title={i18n.t('drivers.faqs.deliveryProcess.question')}>
            <div>
              <p>{i18n.t('drivers.faqs.deliveryProcess.answer1')}</p>
              <p>{i18n.t('drivers.faqs.deliveryProcess.answer2')}</p>
              <p>{i18n.t('drivers.faqs.deliveryProcess.answer3')}</p>
            </div>
          </Accordion>
          <Accordion title={i18n.t('drivers.faqs.askMoreMoney.question')}>
            <div>
              <ReactMarkdown>{i18n.t('drivers.faqs.askMoreMoney.answer1')}</ReactMarkdown>
              <p>{i18n.t('drivers.faqs.askMoreMoney.answer2')}</p>
              <p>{i18n.t('drivers.faqs.askMoreMoney.answer3')}</p>
            </div>
          </Accordion>
          <Accordion title={i18n.t('drivers.faqs.cancelOnMyWay.question')}>
            <div>
              <p>{i18n.t('drivers.faqs.cancelOnMyWay.answer1')}</p>
            </div>
          </Accordion>
          <Accordion title={i18n.t('drivers.faqs.deliverMultipleJobs.question')}>
            <div>
              <p>{i18n.t('drivers.faqs.deliverMultipleJobs.answer1')}</p>
              <p>{i18n.t('drivers.faqs.deliverMultipleJobs.answer2')}</p>
            </div>
          </Accordion>
          <Accordion title={i18n.t('drivers.faqs.senderNotResponding.question')}>
            <div>
              <p>
                {i18n.t('drivers.faqs.senderNotResponding.answer1', { phone: constants.NGINA_SUPPORT_PHONE_NUMBER })}
              </p>
            </div>
          </Accordion>
          <Accordion title={i18n.t('drivers.faqs.cannotReachRecipient.question')}>
            <div>
              <p>{i18n.t('drivers.faqs.cannotReachRecipient.answer1')}</p>
            </div>
          </Accordion>
          <Accordion title={i18n.t('drivers.faqs.pickupDropoffPicture.question')}>
            <div>
              <p>{i18n.t('drivers.faqs.pickupDropoffPicture.answer1')}</p>
              <p>{i18n.t('drivers.faqs.pickupDropoffPicture.answer2')}</p>
            </div>
          </Accordion>
          <Accordion title={i18n.t('general.faqs.dataSecurity.question')}>
            <div>
              <p>{i18n.t('general.faqs.dataSecurity.answer1')}</p>
              <p>{i18n.t('general.faqs.dataSecurity.answer2')}</p>
              <ReactMarkdown>{i18n.t('general.faqs.dataSecurity.answer3')}</ReactMarkdown>
            </div>
          </Accordion>
        </>
      );
    }

    if (user === User.SENDER) {
      return (
        <>
          <Accordion title={i18n.t('sender.faqs.reimburseLostItems.question')}>
            <div>
              <p>{i18n.t('sender.faqs.reimburseLostItems.answer1')}</p>
              <ReactMarkdown>{i18n.t('sender.faqs.reimburseLostItems.answer2')}</ReactMarkdown>
              <p>{i18n.t('sender.faqs.reimburseLostItems.answer3')}</p>
              <p>{i18n.t('sender.faqs.reimburseLostItems.answer4')}</p>
              <ReactMarkdown>{i18n.t('sender.faqs.reimburseLostItems.answer5')}</ReactMarkdown>
              <ReactMarkdown>
                {i18n.t(
                  'sender.faqs.reimburseLostItems.answer6',
                  { phone: constants.NGINA_SUPPORT_PHONE_NUMBER, email: constants.NGINA_SUPPORT_EMAIL }
                )}
              </ReactMarkdown>
            </div>
          </Accordion>
          <Accordion title={i18n.t('sender.faqs.fileClaim.question')}>
            <div>
              <p>{i18n.t('sender.faqs.fileClaim.answer1.text')}</p>
              <ul>
                <li>{i18n.t('sender.faqs.fileClaim.answer1.point1')}</li>
                <li>{i18n.t('sender.faqs.fileClaim.answer1.point2')}</li>
                <li>{i18n.t('sender.faqs.fileClaim.answer1.point3')}</li>
                <li>{i18n.t('sender.faqs.fileClaim.answer1.point4')}</li>
                <li><ReactMarkdown renderers={{ paragraph: 'span' }}>{i18n.t('sender.faqs.fileClaim.answer1.point5')}</ReactMarkdown></li>
              </ul>
              <p>{i18n.t('sender.faqs.fileClaim.answer2')}</p>
              <p>{i18n.t('sender.faqs.fileClaim.answer3')}</p>
            </div>
          </Accordion>
          <Accordion title={i18n.t('sender.faqs.whenCharged.question')}>
            <div>
              <p>{i18n.t('sender.faqs.whenCharged.answer1')}</p>
              <p>{i18n.t('sender.faqs.whenCharged.answer2')}</p>
            </div>
          </Accordion>
          <Accordion title={i18n.t('sender.faqs.driverAsksMoney.question')}>
            <div>
              <ReactMarkdown>{i18n.t('sender.faqs.driverAsksMoney.answer1')}</ReactMarkdown>
              <ReactMarkdown>{i18n.t('sender.faqs.driverAsksMoney.answer2')}</ReactMarkdown>
              <p>{i18n.t('sender.faqs.driverAsksMoney.answer3')}</p>
            </div>
          </Accordion>
          <Accordion title={i18n.t('sender.faqs.shipmentCost.question')}>
            <div>
              <p>{i18n.t('sender.faqs.shipmentCost.answer1')}</p>
              <p>{i18n.t('sender.faqs.shipmentCost.answer2')}</p>
              <p>{i18n.t('sender.faqs.shipmentCost.answer3')}</p>
            </div>
          </Accordion>
          <Accordion title={i18n.t('general.faqs.howDriversMatched.question')}>
            <div>
              <p>{i18n.t('general.faqs.howDriversMatched.answer1')}</p>
              <p>{i18n.t('general.faqs.howDriversMatched.answer2')}</p>
            </div>
          </Accordion>
          <Accordion title={i18n.t('sender.faqs.useNginaForMoving.question')}>
            <div>
              <p>{i18n.t('sender.faqs.useNginaForMoving.answer1')}</p>
              <ReactMarkdown>{i18n.t('sender.faqs.useNginaForMoving.answer2')}</ReactMarkdown>
              <ReactMarkdown>{i18n.t('sender.faqs.useNginaForMoving.answer3')}</ReactMarkdown>
            </div>
          </Accordion>
          <Accordion title={i18n.t('sender.faqs.noDriver.question')}>
            <div>
              <p>{i18n.t('sender.faqs.noDriver.answer1')}</p>
              <p>{i18n.t('sender.faqs.noDriver.answer2')}</p>
            </div>
          </Accordion>
          <Accordion title={i18n.t('sender.faqs.cancellation.question')}>
            <div>
              <p>{i18n.t('sender.faqs.cancellation.answer1')}</p>
            </div>
          </Accordion>
          <Accordion title={i18n.t('sender.faqs.driverContact.question')}>
            <div>
              <p>{i18n.t('sender.faqs.driverContact.answer1')}</p>
            </div>
          </Accordion>
        </>
      );
    }
  }

  useScrollToTop();

  return (
    <>
      <section className="bg-primary-gradient">
        <div className="container">
          <div className="section-heading text-center ml-auto mr-auto mt-5">
            <h2>{i18n.t('general.faqs.title')}</h2>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <nav className="nav nav-underline nav-justified mb-5">
            <div className={`nav-item nav-link ${isActiveUser(User.DRIVER)}`} onClick={() => setUser(User.DRIVER)}>
              {i18n.t('general.faqs.driverTitle')}
            </div>
            <div className={`nav-item nav-link ${isActiveUser(User.SENDER)}`} onClick={() => setUser(User.SENDER)}>
              {i18n.t('general.faqs.senderTitle')}
            </div>
          </nav>
          {renderContent()}
        </div>
      </section>
    </>
  );
};

export default withRouter(Faqs);
