import React from 'react';
import './feature-item.style.scss';
import { blue } from '../../assets/colors';

const FeatureItem = (props) => {
  const renderIcon = () => {
    if (!props.icon) return null;

    return (
      <div>
        <props.icon color={blue} height='30px' width='30px' className="icon" />
        <div className="mb-2"></div>
      </div>
    );
  };

  const renderImage = () => {
    if (!props.imageSrc) return null;

    return <img src={props.imageSrc} className="img-fluid image" alt="" />;
  };

  return (
    <div className={`feature-item ${props.classNames ? props.classNames : ''}`}>
      {renderIcon()}
      {renderImage()}
      {props.title ? <h3>{props.title}</h3> : null}
      {props.subtitle ? <h4>{props.subtitle}</h4> : null}
      <p className="text-muted">{props.text}</p>
    </div>
  );
};

export default FeatureItem;
