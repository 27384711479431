import React from 'react';

import './testimonial.style.scss';
import { I18nContext } from '../../contexts/i18n';
import { withRouter } from '../../utils/withRouter';
import User from '../../services/user';
import margaretAssamo from '../../assets/images/testimonials/margaret-assamo.png';
import randyLeBon from '../../assets/images/testimonials/randy-le-bon.png';
import emmanuelaFoncho from '../../assets/images/testimonials/emmanuela-foncho.png';

const testimonies = [
  {
    name: 'Margaret Assamo',
    imageSrc: margaretAssamo,
    userType: User.SENDER,
    occupation: 'Business owner (Owner of @margbeeluxuryhair)',
    texts: [
      'I had never used Ngina before. I had tried to use it before but I canceled the pickup scheduled for personal reasons.',
      'However a client of mine had to send a parcel to me from Douala to Buea town and she used the app to send it. The driver was a bit late. But in a nutshell I would say NGINA App works.',
      'The next time I would be the one to schedule a pickup because I\'ll be the one sending a parcel with NGINA App. 👌🏿'
    ]
  },
  {
    name: 'Randy Le Bon',
    imageSrc: randyLeBon,
    userType: User.DRIVER,
    occupation: '',
    texts: [
      'Did my first delivery via the NGINA Platform today Douala-Buea and it worked out perfectly well.',
      'Immediately after completing delivery, I was paid instantly through my MoMo Account. Great App, Brilliant Initiative!'
    ]
  },
  {
    name: 'Emmanuela Foncho',
    imageSrc: emmanuelaFoncho,
    userType: User.SENDER,
    occupation: '',
    texts: [
      'I was satisfied with the quality of the service. It\'s so convinient to send and receive packages without having to displace myself.'
    ]
  }
];

const Testimonial = (props) => {
  const { state } = React.useContext(I18nContext);
  const i18n = state.i18n;

  const determinTestimonies = () => {
    if (props.senderOnly) {
      return testimonies.filter(test => test.userType === User.SENDER);
    }

    if (props.driverOnly) {
      return testimonies.filter(test => test.userType === User.DRIVER);
    }

    return testimonies;
  };

  return (
    <section className='testimonial' id='testimonial'>
      <div className='container'>
        <div className="section-heading text-center ml-auto mr-auto">
          <h2>{i18n.t('general.testimonial')}</h2>
          <hr />
        </div>
        <ul className='d-flex flex-wrap justify-content-center'>
          {determinTestimonies().map((item, index) => {
            return (
              <li key={index} className='card mx-2 text-center'>
                <img className="card-img-top rounded-circle mx-auto" src={item.imageSrc} alt='' />
                <div className="card-body pt-5">
                  <h5 className="card-title">{item.name}</h5>
                  <h6 className="card-subtitle text-uppercase mb-3">{i18n.t(`user.${item.userType}`)}</h6>
                  <div className="py-auto">
                    {item.texts.map((text, idx) => {
                      const startQuote = idx === 0 ? '"' : '';
                      const endQuote = idx === item.texts.length - 1 ? '"' : ''
                      return <p key={idx} class="card-text">{`${startQuote}${text}${endQuote}`}</p>
                    })}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default withRouter(Testimonial);
