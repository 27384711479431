import React from 'react';
import { Users, MapPin, Image, CreditCard } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import { withRouter } from '../../utils/withRouter';
import Header from '../../components/Header/Header.component';
import AppDownload from '../../components/AppDownload/AppDownload.component';
import AboutCustomer from '../../components/AboutCustomer/AboutCustomer.component';
import Testimonial from '../../components/Testimonial/Testimonial.component';
import Contact from '../../components/Contact/Contact.component';
import Accordion from '../../components/Accordion';
import { I18nContext } from '../../contexts/i18n';
import useScrollToTop from '../../utils/useScrollToTop';
import { white } from '../../assets/colors';
import driverCar from '../../assets/images/driver-car.png';
import searchJobScreen from '../../assets/images/search-job-screen.jpg';
import detailJobScreen from '../../assets/images/detail-job-screen.jpg';
import './drivers.style.scss';

const STEPS = { one: 1, two: 2, three: 3 };

const Drivers = (props) => {
  const { state } = React.useContext(I18nContext);
  const [howItWorksStep, setHowItWorksStep] = React.useState(STEPS.one);
  const navigate = useNavigate();
  const i18n = state.i18n;

  const isActiveStep = (step) => {
    return step === howItWorksStep ? 'active' : '';
  }

  const renderStep = () => {
    if (howItWorksStep === STEPS.one) {
      return (
        <div className="row mt-5">
          <div className="col-lg-7 my-auto">
            <p>{i18n.t('drivers.howItWorks.step1.text')}</p>
            <ul>
              <li>{i18n.t('drivers.howItWorks.step1.point1')}</li>
              <li>{i18n.t('drivers.howItWorks.step1.point2')}</li>
              <li>{i18n.t('drivers.howItWorks.step1.point3')}</li>
              <li>{i18n.t('drivers.howItWorks.step1.point4')}</li>
              <li>{i18n.t('drivers.howItWorks.step1.point5')}</li>
              <li>{i18n.t('drivers.howItWorks.step1.point6')}</li>
            </ul>
          </div>
          <div className="col-lg-5 my-auto">
            <div className="driver-device-container mt-5 mt-lg-0">
              <div className="device-mockup pixel_3a portrait">
                <div className="device">
                  <div className="screen">
                    <img src={searchJobScreen} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (howItWorksStep === STEPS.two) {
      return (
        <div className="row mt-5">
          <div className="col-lg-7 my-auto">
            <p>{i18n.t('drivers.howItWorks.step2.text')}</p>
            <ul>
              <li>{i18n.t('drivers.howItWorks.step2.point1')}</li>
              <li>{i18n.t('drivers.howItWorks.step2.point2')}</li>
              <li>{i18n.t('drivers.howItWorks.step2.point3')}</li>
              <li>{i18n.t('drivers.howItWorks.step2.point4')}</li>
            </ul>
          </div>
          <div className="col-lg-5 my-auto">
            <div className="driver-device-container mt-5 mt-lg-0">
              <div className="device-mockup pixel_3a portrait">
                <div className="device">
                  <div className="screen">
                    <img src={detailJobScreen} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (howItWorksStep === STEPS.three) {
      return (
        <div className="mt-5">
          <p>{i18n.t('drivers.howItWorks.step3.text')}</p>
          <ul>
            <li>{i18n.t('drivers.howItWorks.step3.point1')}</li>
            <li>{i18n.t('drivers.howItWorks.step3.point2')}</li>
            <li>{i18n.t('drivers.howItWorks.step3.point3')}</li>
          </ul>
        </div>
      );
    }
  }

  useScrollToTop();

  return (
    <>
      <Header
        tag={i18n.t('drivers.title')}
        title={i18n.t('drivers.headerTitle')}
        body={i18n.t('drivers.headerBody')}
      />
      <section>
        <div className="container">
          <div className="section-heading text-center ml-auto mr-auto">
            <h2>{i18n.t('drivers.howItWorks.title')}</h2>
            <p>{i18n.t('drivers.howItWorks.text1')}</p>
            <p>{i18n.t('drivers.howItWorks.text2')}</p>
            <hr />
            <nav className="nav nav-underline nav-justified mt-5">
              <div className={`nav-item nav-link ${isActiveStep(STEPS.one)}`} onClick={() => setHowItWorksStep(STEPS.one)}>
                {i18n.t('drivers.howItWorks.step1.title')}
              </div>
              <div className={`nav-item nav-link ${isActiveStep(STEPS.two)}`} onClick={() => setHowItWorksStep(STEPS.two)}>
                {i18n.t('drivers.howItWorks.step2.title')}
              </div>
              <div className={`nav-item nav-link ${isActiveStep(STEPS.three)}`} onClick={() => setHowItWorksStep(STEPS.three)}>
                {i18n.t('drivers.howItWorks.step3.title')}
              </div>
            </nav>
          </div>
          {renderStep()}
        </div>
      </section>
      <AppDownload />
      <AboutCustomer
        title={i18n.t('drivers.whyDrive.title')}
        text={i18n.t('drivers.whyDrive.text')}
        texts={[
          { title: i18n.t('drivers.whyDrive.moreEarnings.title'), text: i18n.t('drivers.whyDrive.moreEarnings.text') },
          { title: i18n.t('drivers.whyDrive.choose.title'), text: i18n.t('drivers.whyDrive.choose.text') },
          { title: i18n.t('drivers.whyDrive.anyVehicle.title'), text: i18n.t('drivers.whyDrive.anyVehicle.text') }
        ]}
      />
      <Testimonial driverOnly />
      <section className="bg-primary-gradient">
        <div className="container">
          <div className="section-heading text-center ml-auto mr-auto">
            <h2>{i18n.t('drivers.requirements.title')}</h2>
            <hr className="light"/>
          </div>
          <p className="mt-5">{i18n.t('drivers.requirements.text')}</p>
          <div className="row">
            {[
              { icon: CreditCard, text: i18n.t('drivers.requirements.item1')},
              { icon: CreditCard, text: i18n.t('drivers.requirements.item2')},
              { icon: Image, text: i18n.t('drivers.requirements.item3')},
              { icon: Image, text: i18n.t('drivers.requirements.item4')},
              { icon: Image, text: i18n.t('drivers.requirements.item5')},
              { icon: Users, text: i18n.t('drivers.requirements.item6')},
              { icon: MapPin, text: i18n.t('drivers.requirements.item7')}
            ].map((item, index) => (
              <div className="col-lg-6" key={index}>
                <item.icon color={white} />
                <span className="pl-2">{item.text}</span>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="section-heading text-center ml-auto mr-auto mb-5">
            <h2>{i18n.t('general.faqs.title')}</h2>
            <hr />
          </div>
          <Accordion title={i18n.t('drivers.faqs.whatINeedToBeADriver.question')}>
            <p>{i18n.t('drivers.faqs.whatINeedToBeADriver.answer1')}</p>
          </Accordion>
          <Accordion title={i18n.t('drivers.faqs.whyBackgroundChecks.question')}>
            <div>
              <p>{i18n.t('drivers.faqs.whyBackgroundChecks.answer1')}</p>
              <p>{i18n.t('drivers.faqs.whyBackgroundChecks.answer2')}</p>
              <p>{i18n.t('drivers.faqs.whyBackgroundChecks.answer3')}</p>
            </div>
          </Accordion>
          <Accordion title={i18n.t('drivers.faqs.howMuchPay.question')}>
            <p>{i18n.t('drivers.faqs.howMuchPay.answer1')}</p>
          </Accordion>

          <div className="text-center">
            <button
              className="btn btn-primary btn-xl mt-5"
              onClick={() => navigate('/faqs')}
            >
              {i18n.t('general.faqs.seeAll')}
            </button>
          </div>
        </div>
      </section>
      <Contact
        title={i18n.t('drivers.footerTitle')}
        buttonTitle={i18n.t('general.signUpNow')}
        link='/download/android'
        image={driverCar}
      />
    </>
  );
};

export default withRouter(Drivers);
