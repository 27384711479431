import React from 'react';
import Header from '../components/Header/Header.component';
import AppDownload from '../components/AppDownload/AppDownload.component';
import About from '../components/About/About.component';
import Contact from '../components/Contact/Contact.component';
import GetQuote from '../components/GetQuote/GetQuote.component';
import Testimonial from '../components/Testimonial/Testimonial.component';
import { I18nContext } from '../contexts/i18n';
import useScrollToTop from '../utils/useScrollToTop';
import personImage from '../assets/images/man-phone.png';
import luggageImage from '../assets/images/luggage.png';

const Home = () => {
  const { state } = React.useContext(I18nContext);
  const i18n = state.i18n;
  useScrollToTop();

  return (
    <>
      <Header
        title={i18n.t('home.headerTitle')}
        body={i18n.t('home.headerBody')}
      />
      <section className="note-section shift-up">
        <div className="container">
          <div className="section-heading text-center ml-auto mr-auto">
            <h2>{i18n.t('home.deliverBetter.title')}</h2>
            <p className="text-muted">{i18n.t('home.deliverBetter.text')}</p>
          </div>
        </div>
      </section>
      <About />
      <GetQuote
        title={i18n.t('quote.title')}
        buttonTitle={i18n.t('quote.header')}
        link='/quote'
        image={luggageImage}
        category={i18n.t('quote.form.category.luggage')}
        size={i18n.t('quote.form.size.large')}
        minCost={2000}
        maxCost={5000}
      />
      <AppDownload />
      <section className="bg-grey" id="about_customer">
      <div className="note-section">
        <div className="container-fluid">
          <div className="section-heading text-center ml-auto mr-auto">
            <h2>{i18n.t('home.trust.title')}</h2>
            <hr />
          </div>
          <p className="mt-4">{i18n.t('home.trust.text')}</p>
        </div>
      </div>
      </section>
      <Testimonial />
      <Contact
        title={i18n.t('home.contact')}
        buttonTitle={i18n.t('contactUs.title')}
        link='/contact-us'
        image={personImage}
      />
    </>
  );
};

export default Home;
