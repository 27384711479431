import React from 'react';
import { I18nContext } from '../contexts/i18n';
import useScrollToTop from '../utils/useScrollToTop';

const AboutUs = () => {
  const { state } = React.useContext(I18nContext);
  const i18n = state.i18n;
  useScrollToTop();

  return (
    <>
      <section className="bg-primary-gradient">
        <div className="container">
          <div className="section-heading text-center ml-auto mr-auto mt-5">
            <h2>{i18n.t('aboutUs.title')}</h2>
          </div>
        </div>
      </section>
      <section className="note-section shift-up">
        <div className="container">
          <div className="section-heading text-center ml-auto mr-auto">
            <h2>{i18n.t('aboutUs.headerTitle')}</h2>
            <p>{i18n.t('aboutUs.headerText1')}</p>
            <p>{i18n.t('aboutUs.headerText2')}</p>
            <p><b>{i18n.t('aboutUs.headerText3')}</b></p>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
