import parsePhoneNumber from 'libphonenumber-js';

export const decamelize = (str) => {
  return str.split(/(?=[A-Z])/).join('_').toLowerCase();
};

export const camelize = (str) => {
  return str.replace(/^([A-Z])|[\s-_]+(\w)/g, (match, p1, p2, offset) => {
    if (p2) return p2.toUpperCase();
    return p1.toLowerCase();
  });
};

export const capitalize = (str) => {
  if (typeof str !== 'string') return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const removeSpaces = (str) => {
  return str.replace(/\s+/g, '');
};

export const currencyFormat = (num) => {
   return  num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export const phoneFormat = (phone) => {
  const phoneNumber = parsePhoneNumber(phone);
  return phoneNumber.formatInternational();
}
