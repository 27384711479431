const black = '#2a2c2b';
const blue = '#4d73d1';
const green = '#4dd16a';
const grey = '#bdc3c7';
const greyLight = '#d7dbdd';
const greyTwo = '#8a9094';
const orange = '#eac466';
const primaryColor = '#d0aa4c';
const primaryColorLight = '#d9cb9e';
const red = '#ff0004';
const redTwo = '#ff3c00';
const white = '#f9f9f9';

export {
  black,
  blue,
  green,
  grey,
  greyLight,
  greyTwo,
  orange,
  primaryColor,
  primaryColorLight,
  red,
  redTwo,
  white
};
