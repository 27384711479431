import React from 'react';
import { NavLink } from 'react-router-dom';

import { withRouter } from '../../utils/withRouter';
import Price from '../Price';
import { I18nContext } from '../../contexts/i18n';
import './get-quote.style.scss';

const GetQuote = (props) => {
  const { state } = React.useContext(I18nContext);
  const i18n = state.i18n;

  return (
    <section className="get-quote-section py-0">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-6 h-100">
            <div className="row no-gutters pt-5 pt-lg-0 h-100">
              <div className={`col-7 col-sm-6 image-container vertical-align-center h-100 ${props.shifted ? 'shifted-up' : ''}`}>
                <img src={props.image} className="img-fluid" alt="" />
              </div>
              <div className="col-5 col-sm-6 price-info">
                <div className="large-text">{props.category}</div>
                <div className="mt-2">
                  <small className="text-uppercase font-weight-bold">{`${i18n.t('quote.form.size.label')}: `}</small>{props.size}
                </div>
                <div>
                  <small className="text-uppercase font-weight-bold">{`${i18n.t('quote.cost')}: `}</small><Price amount={props.minCost} /> - <Price amount={props.maxCost} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 h-100 vertical-align-center justify-content-center justify-content-lg-end">
            <div className="my-5 my-lg-0 d-flex flex-column">
              <h1 className="mx-auto ml-lg-0">{props.title}</h1>
              <NavLink to={props.link} className="btn btn-outline btn-xl mt-3 mx-auto ml-lg-0">{props.buttonTitle}</NavLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(GetQuote);
