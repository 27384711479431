import React from 'react';
import ReactMarkdown from 'react-markdown';

import { withRouter } from '../../utils/withRouter';
import PersonalSubNav from '../../components/PersonalSubNav';
import AppDownload from '../../components/AppDownload/AppDownload.component';
import { I18nContext } from '../../contexts/i18n';
import useScrollToTop from '../../utils/useScrollToTop';
import constructionEquipment from  '../../assets/images/construction-equipment.jpg';

const ConstructionEquipment = (props) => {
  const { state } = React.useContext(I18nContext);
  const i18n = state.i18n;

  useScrollToTop();

  return (
    <>
      <PersonalSubNav classNames='mt-5 pt-2' />
      <section>
        <div className="container">
          <div className="row section-heading">
            <div className="col-lg-8 my-auto">
              <div className="tag">{i18n.t('sender.personal.constructionEquipment.label')}</div>
              <h1>{i18n.t('sender.personal.constructionEquipment.headerTitle')}</h1>
              <a href="#download" className="btn btn-primary btn-xl js-scroll-trigger">{i18n.t('general.sendNow')}</a>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-grey">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img src={constructionEquipment} className="img-fluid w-100 mb-3" alt="" />
              <p>{i18n.t('sender.personal.constructionEquipment.text1')}</p>
              <h5 className="font-weight-bold">{i18n.t('sender.personal.constructionEquipment.heading2')}</h5>
              <p>{i18n.t('sender.personal.constructionEquipment.text2')}</p>
            </div>
            <div className="col-md-6">
              <h5 className="font-weight-bold">{i18n.t('sender.personal.constructionEquipment.heading3')}</h5>
              <ul>
                <li><ReactMarkdown source={i18n.t('sender.personal.constructionEquipment.point31')} /></li>
                <li><ReactMarkdown source={i18n.t('sender.personal.constructionEquipment.point32')} /></li>
                <li><ReactMarkdown source={i18n.t('sender.personal.constructionEquipment.point33')} /></li>
                <li><ReactMarkdown source={i18n.t('sender.personal.constructionEquipment.point34')} /></li>
                <li><ReactMarkdown source={i18n.t('sender.personal.constructionEquipment.point35')} /></li>
              </ul>
              <h5 className="font-weight-bold">{i18n.t('sender.personal.constructionEquipment.heading4')}</h5>
              <p>{i18n.t('sender.personal.constructionEquipment.text4')}</p>
            </div>
          </div>
        </div>
      </section>
      <AppDownload />
    </>
  );
};

export default withRouter(ConstructionEquipment);
