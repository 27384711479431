import i18n from 'i18n-js';

import en from './en.json';
import fr from './fr.json';
import termsEn from './terms.en.md';
import termsFr from './terms.fr.md';
import privacyEn from './privacy.en.md';
import privacyFr from './privacy.fr.md';

i18n.fallbacks = true;
i18n.translations = { en, fr };

const EN = 'en';
const FR = 'fr';

export { i18n, EN, FR, termsEn, termsFr, privacyEn, privacyFr };
