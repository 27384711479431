import React from 'react';

const TextAreaField = (props) => {
  const renderError = () => {
    if (!props.errors) return null;

    return <div className="invalid-feedback show">{props.errorMessage}</div>;
  };

  return (
    <>
      <textarea
        id={props.fieldName}
        name={props.fieldName}
        placeholder={props.placeholderText}
        onChange={event => props.handleChange(event.target.value)}
        onBlur={props.handleBlur}
        value={props.value}
        className={`form-control ${props.errors ? 'is-invalid' : ''}`}
        rows={3}
      />
      {renderError()}
    </>
  );
};

export default TextAreaField;
