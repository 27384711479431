import React from 'react';

const RadioField = (props) => {
  const renderError = () => {
    if (!props.errors) return null;

    return <div className="invalid-feedback show">{props.errorMessage}</div>;
  };

  return (
    <>
      {props.options.map((option, index) => (
        <div className='custom-control custom-radio' key={index}>
          <input
            id={`${option}${index}`}
            className='custom-control-input'
            type='radio'
            value={option.val}
            checked={props.value === option.val}
            onChange={event => props.handleChange(event.target.value)}
          />
          <label className='custom-control-label' htmlFor={`${option}${index}`}>{option.label}</label>
        </div>
      ))}
      {renderError()}
    </>
  );
};

export default RadioField;
