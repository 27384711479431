import React from 'react';
import { CheckboxOutline } from 'react-ionicons';
import { Shield } from 'react-feather';
import { EyeOutline } from 'react-ionicons';
import 'simple-line-icons/css/simple-line-icons.css';
import './about.style.css';
import FeatureItem from '../FeatureItem/FeatureItem.component';
import Cards from '../Cards/Cards.component';
import { I18nContext } from '../../contexts/i18n';

const About = (props) => {
  const { state } = React.useContext(I18nContext);
  const i18n = state.i18n;

  return <section className="about pt-5" id="about">
    <div className="container">
      <div className="section-heading text-center ml-auto mr-auto">
        <h2>{i18n.t('home.whyNgina.title')}</h2>
        <p>{i18n.t('home.whyNgina.text1')}</p>
        <p>{i18n.t('home.whyNgina.text2')}</p>
        <hr />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <FeatureItem
              icon={EyeOutline}
              title={i18n.t('home.whyNgina.transparency.title')}
              text={i18n.t('home.whyNgina.transparency.text')}
              classNames='text-center'
            />
          </div>
          <div className="col-md-4">
            <FeatureItem
              icon={CheckboxOutline}
              title={i18n.t('home.whyNgina.confirmation.title')}
              text={i18n.t('home.whyNgina.confirmation.text')}
              classNames='text-center'
            />
          </div>
          <div className="col-md-4">
            <FeatureItem
              icon={Shield}
              title={i18n.t('home.whyNgina.secure.title')}
              text={i18n.t('home.whyNgina.secure.text')}
              classNames='text-center'
            />
          </div>
        </div>
        <br />
        <Cards
          link={true}
          items={[
            {
              title: i18n.t('sender.smallBusiness.title'),
              text: i18n.t('home.whyNgina.smallBusiness'),
              footer: `${i18n.t('general.learnMore')} >`.toUpperCase(),
              path: '/small-business'
            },
            {
              title: i18n.t('sender.personal.title'),
              text: i18n.t('home.whyNgina.personal'),
              footer: `${i18n.t('general.learnMore')} >`.toUpperCase(),
              path: '/personal'
            },
            {
              title: i18n.t('drivers.title'),
              text: i18n.t('home.whyNgina.drivers'),
              footer: `${i18n.t('general.learnMore')} >`.toUpperCase(),
              path: '/drivers'
            }
          ]}
        />
      </div>
    </div>
  </section>
};

export default About;
