import React from 'react';
import { Phone, MapPin, Mail } from 'react-feather';
import { useFormik } from 'formik';
import { Slide, ToastContainer, toast } from 'react-toastify';
import { RadioField, PhoneInputField, StringInputField, TextAreaField } from '../../components/Form';
import FeatureItem from '../../components/FeatureItem/FeatureItem.component';
import { I18nContext } from '../../contexts/i18n';
import User from '../../services/user';
import Contact from '../../services/contact';
import { contactSchema } from '../../services/formValidators';
import useScrollToTop from '../../utils/useScrollToTop';
import constants from '../../Constants';
import { phoneFormat } from '../../utils/string-helper';
import './contact-us.style.scss';

const ContactUs = () => {
  const { state } = React.useContext(I18nContext);
  const i18n = state.i18n;

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      userType: '',
      message: ''
    },
    validationSchema: contactSchema,
    onSubmit: values => handleContactSubmit(values)
  });

  const { handleSubmit, values, handleBlur, setFieldValue, errors, submitCount } = formik;

  const handleContactSubmit = (values) => {
    Contact.send(values).then(() => {
      toast.success(i18n.t('contactUs.send.success'));
    }).catch(() => {
      toast.error(i18n.t('contactUs.send.failure'))
    });
  };

  useScrollToTop();

  return (
    <>
      <section className="bg-primary-gradient">
        <div className="container">
          <div className="section-heading text-center ml-auto mr-auto mt-5">
            <div>{i18n.t('contactUs.title')}</div>
            <h2>{i18n.t('contactUs.heading')}</h2>
          </div>
        </div>
      </section>
      <section>
        <div className="container" id="#contactUs">
          <div className="row">
            <div className="col-sm-4">
              <FeatureItem
                icon={Phone}
                title={i18n.t('contactUs.phone.title')}
                text={phoneFormat(constants.NGINA_SUPPORT_PHONE_NUMBER)}
                classNames='text-center'
              />
            </div>
            <div className="col-sm-4">
              <FeatureItem
                icon={MapPin}
                title={i18n.t('contactUs.address.title')}
                text={i18n.t('contactUs.address.text')}
                classNames='text-center'
              />
            </div>
            <div className="col-sm-4">
              <FeatureItem
                icon={Mail}
                title={i18n.t('contactUs.email.title')}
                text={constants.NGINA_SUPPORT_EMAIL}
                classNames='text-center'
              />
            </div>
          </div>

          <div className="section-heading text-center ml-auto mr-auto mt-5" id="#contactUs">
            <h2>{i18n.t('contactUs.getInTouch.title')}</h2>
            <div>{i18n.t('contactUs.getInTouch.text')}</div>
            <hr />
          </div>

          <form onSubmit={handleSubmit} className="contact-us-form mt-5">
            <div className="row">
              <div className="form-group col-md-6">
                <label>{`${i18n.t('contactUs.form.firstName')} *`}</label>
                <StringInputField
                  value={values.firstName}
                  handleChange={val => setFieldValue('firstName', val)}
                  hanldeBlur={handleBlur}
                  placeholderText=''
                  errors={submitCount > 0 && errors.firstName}
                  errorMessage={errors.firstName}
                />
              </div>
              <div className="form-group col-md-6 mt-3 mt-md-0">
                <label>{`${i18n.t('contactUs.form.lastName')} *`}</label>
                <StringInputField
                  value={values.lastName}
                  handleChange={val => setFieldValue('lastName', val)}
                  hanldeBlur={handleBlur}
                  placeholderText=''
                  errors={submitCount > 0 && errors.lastName}
                  errorMessage={errors.lastName}
                />
              </div>
              <div className="form-group col-md-6 mt-3">
                <label>{`${i18n.t('contactUs.form.phone')} *`}</label>
                <PhoneInputField
                  value={values.phone}
                  handleChange={val => setFieldValue('phone', val)}
                  hanldeBlur={handleBlur}
                  defaultCountry={'cm'}
                  countrySelectDisabled={true}
                  placeholderText=''
                  errors={submitCount > 0 && errors.phone}
                  errorMessage={errors.phone}
                />
              </div>
              <div className="form-group col-md-6 mt-3">
                <label>{`${i18n.t('contactUs.form.email')} *`}</label>
                <StringInputField
                  value={values.email}
                  handleChange={val => setFieldValue('email', val)}
                  hanldeBlur={handleBlur}
                  placeholderText=''
                  errors={submitCount > 0 && errors.email}
                  errorMessage={errors.email}
                />
              </div>
              <div className="form-group col-12 mt-3">
                <label>{`${i18n.t('contactUs.form.userType.title')} *`}</label>
                <RadioField
                  value={values.userType}
                  handleChange={val => setFieldValue('userType', val)}
                  options={User.CONTACT_TYPES.map(type => {
                    return { val: type === User.SMALL_BUSINESS ? 'small_business' : type, label: i18n.t(`contactUs.form.userType.${type}`) };
                  })}
                  errors={submitCount > 0 && errors.userType}
                  errorMessage={errors.userType}
                />
              </div>
              <div className="form-group col-12 mt-3">
                <label>{`${i18n.t('contactUs.form.message')} *`}</label>
                <TextAreaField
                  value={values.message}
                  handleChange={val => setFieldValue('message', val)}
                  hanldeBlur={handleBlur}
                  placeholderText=''
                  errors={submitCount > 0 && errors.message}
                  errorMessage={errors.message}
                />
              </div>

              <button
                className="btn btn-primary btn-xl mx-auto mt-3"
                type="submit"
              >
                {i18n.t('contactUs.form.submit')}
              </button>
            </div>
          </form>
        </div>
      </section>
      <ToastContainer
        position={toast.POSITION.TOP_CENTER}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        limit={3}
        transition={Slide}
      />
    </>
  );
};

export default ContactUs;
