import React from 'react';
import { I18nContext } from '../../contexts/i18n';
import './sender-how-it-works.style.scss';
import '../../assets/device-mockups/device-mockups.css';
import createJobScreen from '../../assets/images/create-job-screen.jpg';
import senderJobsScreen from '../../assets/images/sender-jobs-screen.jpg';

const STEPS = { one: 1, two: 2, three: 3 };

const SenderHowItWorks = () => {
  const { state } = React.useContext(I18nContext);
  const [howItWorksStep, setHowItWorksStep] = React.useState(STEPS.one);
  const i18n = state.i18n;

  const isActiveStep = (step) => {
    return step === howItWorksStep ? 'active' : '';
  };

  const renderStep = () => {
    if (howItWorksStep === STEPS.one) {
      return (
        <div className="row mt-5">
          <div className="col-lg-7 my-auto">
            <h5>{i18n.t('sender.howItWorks.step1.subTitle')}</h5>
            <p>{i18n.t('sender.howItWorks.step1.text')}</p>
            <ul>
              <li>{i18n.t('sender.howItWorks.step1.point1')}</li>
              <li>{i18n.t('sender.howItWorks.step1.point2')}</li>
              <li>{i18n.t('sender.howItWorks.step1.point3')}</li>
            </ul>
          </div>
          <div className="col-lg-5 my-auto">
            <div className="sender-device-container mt-5 mt-lg-0">
              <div className="device-mockup pixel_3a portrait">
                <div className="device">
                  <div className="screen">
                    <img src={createJobScreen} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (howItWorksStep === STEPS.two) {
      return (
        <div className="row mt-5">
          <div className="col-lg-7 my-auto">
            <h5>{i18n.t('sender.howItWorks.step2.subTitle')}</h5>
            <p>{i18n.t('sender.howItWorks.step2.text')}</p>
            <ul>
              <li>{i18n.t('sender.howItWorks.step2.point1')}</li>
              <li>{i18n.t('sender.howItWorks.step2.point2')}</li>
            </ul>
          </div>
          <div className="col-lg-5 my-auto">
            <div className="sender-device-container mt-5 mt-lg-0">
              <div className="device-mockup pixel_3a portrait">
                <div className="device">
                  <div className="screen">
                    <img src={senderJobsScreen} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (howItWorksStep === STEPS.three) {
      return (
        <div className="row mt-5">
          <h5>{i18n.t('sender.howItWorks.step3.subTitle')}</h5>
          <p>{i18n.t('sender.howItWorks.step3.text')}</p>
          <ul>
            <li>{i18n.t('sender.howItWorks.step3.point1')}</li>
            <li>{i18n.t('sender.howItWorks.step3.point2')}</li>
            <li>{i18n.t('sender.howItWorks.step3.point3')}</li>
          </ul>
        </div>
      );
    }
  };

  return (
    <section>
      <div className="container">
        <div className="section-heading text-center ml-auto mr-auto">
          <h2>{i18n.t('sender.howItWorks.title')}</h2>
          <p>{i18n.t('sender.howItWorks.text')}</p>
          <hr />
          <nav className="nav nav-underline nav-justified mt-5">
            <div className={`nav-item nav-link ${isActiveStep(STEPS.one)}`} onClick={() => setHowItWorksStep(STEPS.one)}>
              {i18n.t('sender.howItWorks.step1.title')}
            </div>
            <div className={`nav-item nav-link ${isActiveStep(STEPS.two)}`} onClick={() => setHowItWorksStep(STEPS.two)}>
              {i18n.t('sender.howItWorks.step2.title')}
            </div>
            <div className={`nav-item nav-link ${isActiveStep(STEPS.three)}`} onClick={() => setHowItWorksStep(STEPS.three)}>
              {i18n.t('sender.howItWorks.step3.title')}
            </div>
          </nav>
        </div>
        {renderStep()}
      </div>
    </section>
  );
};

export default SenderHowItWorks;
