import React from 'react';

const SelectField = (props) => {
  const renderError = () => {
    if (!props.errorMessage) return null;

    return <div className="invalid-feedback">{props.errorMessage}</div>;
  };

  return (
    <>
      <select
        className={`form-control ${props.errors ? 'is-invalid' : ''} ${props.value ? '' : 'text-grey'}`}
        value={props.value}
        onChange={event => props.handleChange(event.target.value)}
        disabled={props.disabled || false}
      >
        <option disabled key={-1} value=''>{props.placeholderText}</option>
        {props.options.map((opt, index) => {
          return <option key={index} value={opt.value}>{opt.label}</option>;
        })}
      </select>
      {renderError()}
    </>
  );
};

export default SelectField;
