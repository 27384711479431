import React from 'react';
import { ChevronDown } from 'react-ionicons';
import { ChevronForward } from 'react-ionicons';
import './accordion.style.scss';

const Accordion = (props) => {
  const [activeState, setActiveState] = React.useState('');
  const [heightState, setHeightState] = React.useState('0px');

  const content = React.useRef(null);

  const toggleAccordion = () => {
    setActiveState(activeState === '' ? 'active' : '');
    setHeightState(activeState === 'active' ? '0px' : `${content.current.scrollHeight}px`);
  };

  const renderIcon = () => {
    return activeState === 'active' ? <ChevronDown /> : <ChevronForward />;
  }

  return (
    <div className="accordion-section">
      <button className={`accordion ${activeState}`} onClick={toggleAccordion}>
        <p className="accordion-title">{props.title}</p>
        <div className="accordion-icon">{renderIcon(activeState)}</div>
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${heightState}` }}
        className="accordion-content"
      >
        {props.children}
      </div>
    </div>
  );
};

export default Accordion;
