import * as yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { i18n } from '../i18n';

const required = yup.mixed().required(i18n.t('validation.required'));

const email =  yup.string().required(i18n.t('validation.required')).email(i18n.t('validation.emailInvalid'));

const phone = yup.string().default(() => (''))
                    .test(
                      'invalid',
                      i18n.t('validation.phoneInvalid'),
                      value => {
                        if (!value) return false;

                        const phoneNumber = parsePhoneNumberFromString(`+${value}`);

                        if (!phoneNumber) return false;
                        return phoneNumber.isValid();
                      });

export const contactSchema = yup.object().shape({
  firstName: required,
  lastName: required,
  phone: phone,
  email: email,
  userType: required,
  message: required
});

export const quoteSchema = yup.object().shape({
  category: required,
  size: required,
  travelType: required
});
