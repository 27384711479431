import React from 'react';
import { i18n, EN, FR } from '../i18n';
import localeProvider from '../utils/locale-provider';

const I18nContext = React.createContext();

i18n.locale = localeProvider().includes(FR) ? FR : EN;
const initialState = { i18n };

const reducer = (state, action) => {
  switch (action) {
    case 'ENGLISH':
      i18n.locale = EN;
      return { i18n };
    case 'FRENCH':
      i18n.locale = FR;
      return { i18n };
    default:
      return state;
  }
};

export { I18nContext, initialState, reducer };
