import ApiBase from './apiBase';

export default class Shipment extends ApiBase {
  static get categories() {
    const myself = new Shipment();
    return myself.httpClient.get(`${myself.apiHostUrl}/shipments/categories`).then(response => response.data.data);
  }

  static getPrice(attrs) {
    const myself = new Shipment();
    return myself.httpClient.get(
      `${myself.apiHostUrl}/shipments/price`,
      { params: { category: attrs.category, size: attrs.size, travel_type: attrs.travelType } }
    ).then(response => response.data.data);
  }
};
