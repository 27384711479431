import React from 'react';
import { NavLink } from 'react-router-dom';
import { HomeOutline } from 'react-ionicons';
import { PhonePortraitOutline } from 'react-ionicons';
import { EaselOutline } from 'react-ionicons';
import { BasketOutline } from 'react-ionicons';

import { I18nContext } from '../contexts/i18n';
import { black } from '../assets/colors';

const PersonalSubNav = (props) => {
  const { state } = React.useContext(I18nContext);
  const i18n = state.i18n;
  const navType = props.type ? props.type : 'underline';
  const navTypeIsBox = navType === 'box';

  return (
    <nav className={`nav nav-${navType} nav-justified ${props.classNames}`}>
      <NavLink to="/personal/bulk-items" className={({ isActive }) => "nav-item nav-link" + (isActive ? " active" : "")}>
        {navTypeIsBox ? <div className="mb-2"><BasketOutline color={black} fontSize='30px' /></div> : null}
        {i18n.t('sender.personal.bulkItems.title')}
      </NavLink>
      <NavLink to="/personal/construction-equipment" className={({ isActive }) => "nav-item nav-link" + (isActive ? " active" : "")}>
        {navTypeIsBox ? <div className="mb-2"><HomeOutline color={black} fontSize='30px' /></div> : null}
        {i18n.t('sender.personal.constructionEquipment.title')}
      </NavLink>
      <NavLink to="/personal/furniture" className={({ isActive }) => "nav-item nav-link" + (isActive ? " active" : "")}>
        {navTypeIsBox ? <div className="mb-2"><EaselOutline color={black} fontSize='30px' /></div> : null}
        {i18n.t('sender.personal.furniture.title')}
      </NavLink>
      <NavLink to="/personal/social-media-retailers" className={({ isActive }) => "nav-item nav-link" + (isActive ? " active" : "")}>
        {navTypeIsBox ? <div className="mb-2"><PhonePortraitOutline color={black} fontSize='30px' /></div> : null}
        {i18n.t('sender.personal.socialMediaRetailers.title')}
      </NavLink>
    </nav>
  );
};

export default PersonalSubNav;
