export default class User {
  static get TYPE() {
    return { sender: User.SENDER, driver: User.DRIVER, trucker: User.TRUCKER };
  }

  static get CONTACT_TYPES() {
    return [User.DRIVER, User.TRUCKER, User.SMALL_BUSINESS, User.PERSONAL, User.OTHER];
  }

  static get DRIVER() { return 'driver'; }
  static get TRUCKER() { return 'trucker'; }
  static get SENDER() { return 'sender'; }
  static get PERSONAL() { return 'personal'; }
  static get SMALL_BUSINESS() { return 'smallBusiness'; }
  static get OTHER() { return 'other'; }
};
