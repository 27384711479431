import React from 'react';
import { NavLink } from 'react-router-dom';
import { CarOutline } from 'react-ionicons';
import { LaptopOutline } from 'react-ionicons';
import { MedkitOutline } from 'react-ionicons';
import { CogOutline } from 'react-ionicons';
import { EaselOutline } from 'react-ionicons';
import { IceCreamOutline } from 'react-ionicons';

import { I18nContext } from '../contexts/i18n';
import { black } from '../assets/colors';

const SmallBusinessSubNav = (props) => {
  const { state } = React.useContext(I18nContext);
  const i18n = state.i18n;
  const navType = props.type ? props.type : 'underline';
  const navTypeIsBox = navType === 'box';

  return (
    <nav className={`nav nav-${navType} nav-justified ${props.classNames}`}>
      <NavLink to="/small-business/artisan-foods" className={({ isActive }) => "nav-item nav-link" + (isActive ? " active" : "")}>
        {navTypeIsBox ? <div className="mb-2"><IceCreamOutline color={black} fontSize='30px' /></div> : null}
        {i18n.t('sender.smallBusiness.artisanFoods.title')}
      </NavLink>
      <NavLink to="/small-business/hardware-equipment" className={({ isActive }) => "nav-item nav-link" + (isActive ? " active" : "")}>
        {navTypeIsBox ? <div className="mb-2"><CarOutline color={black} fontSize='30px' /></div> : null}
        {i18n.t('sender.smallBusiness.automotive.title')}
      </NavLink>
      <NavLink to="/small-business/ecommerce" className={({ isActive }) => "nav-item nav-link" + (isActive ? " active" : "")}>
        {navTypeIsBox ? <div className="mb-2"><LaptopOutline color={black} fontSize='30px' /></div> : null}
        {i18n.t('sender.smallBusiness.ecommerce.title')}
      </NavLink>
      <NavLink to="/small-business/heavy-equipment" className={({ isActive }) => "nav-item nav-link" + (isActive ? " active" : "")}>
        {navTypeIsBox ? <div className="mb-2"><CogOutline color={black} fontSize='30px' /></div> : null}
        {i18n.t('sender.smallBusiness.heavyEquipment.title')}
      </NavLink>
      <NavLink to="/small-business/home-decor" className={({ isActive }) => "nav-item nav-link" + (isActive ? " active" : "")}>
        {navTypeIsBox ? <div className="mb-2"><EaselOutline color={black} fontSize='30px' /></div> : null}
        {i18n.t('sender.smallBusiness.homeDecor.title')}
      </NavLink>
      <NavLink to="/small-business/pharmacy-delivery" className={({ isActive }) => "nav-item nav-link" + (isActive ? " active" : "")}>
        {navTypeIsBox ? <div className="mb-2"><MedkitOutline color={black} fontSize='30px' /></div> : null}
        {i18n.t('sender.smallBusiness.pharmacyDelivery.title')}
      </NavLink>
    </nav>
  );
};

export default SmallBusinessSubNav;
