import React from 'react';

import './app-download.style.scss';
import googlePlayBadge from './img/google-play-badge.svg';
import { I18nContext } from '../../contexts/i18n';
import constants from '../../Constants';
import { sendAppClickEvent } from '../../useGaTracker';
import { withRouter } from '../../utils/withRouter';

const AppDownload = (_props) => {
  const { state } = React.useContext(I18nContext);
  const i18n = state.i18n;

  const sendGAEvent = () => {
    sendAppClickEvent();
    return true;
  }

  return (
    <section className="download bg-primary-gradient text-center" id="download">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <h2 className="section-heading">{i18n.t('general.download.title')}</h2>
            <br />
            <h5 className="bold-text">{i18n.t('general.download.text')}</h5>
            <br />
            <div className="badges">
              <a
                className="badge-link"
                onClick={() => sendGAEvent()}
                href={constants.APP_GOOGLE_PLAYSTORE_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={googlePlayBadge} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(AppDownload);
