import React from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import { AppsOutline } from 'react-ionicons';
import { MenuOutline } from 'react-ionicons'

import './navbar.style.scss';
import { withRouter } from '../../utils/withRouter';
import logo from '../../assets/images/logo.svg';
import { I18nContext } from '../../contexts/i18n';

const NavBar = (_props) => {
  const [menuShow, setMenuShow] = React.useState(false);
  const { state } = React.useContext(I18nContext);
  let navigate = useNavigate();
  const i18n = state.i18n;
  const show = menuShow ? 'show' : '';
  const location = useLocation();

  React.useEffect(() => {
    Events.scrollEvent.register('begin', () => closeMenu());

    return () => Events.scrollEvent.remove('begin');
  });

  React.useEffect(() => {
    Events.scrollEvent.register('end', () => {});

    return () => Events.scrollEvent.remove('end');
  }, []);

  React.useEffect(() => {
    scrollSpy.update();
  }, []);

  const isFreightLocation = () => location.pathname.includes('freight');

  const closeMenu = () => {
    if (menuShow) setMenuShow(false);
  };

  const toggleShow = () => setMenuShow(!menuShow);

  const scrollToTop = () => scroll.scrollToTop();

  const routeToHome = () => {
    isFreightLocation() ? navigate('/freight') : navigate('/');
    scrollToTop();
  };

  const renderLinks = () => {
    return (
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <NavLink
            to="/small-business"
            className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}
            onClick={() => closeMenu()}
          >
            {i18n.t('sender.smallBusiness.title')}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/personal"
            className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}
            onClick={() => closeMenu()}
          >
            {i18n.t('sender.personal.title')}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/drivers"
            className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}
            onClick={() => closeMenu()}
          >
            {i18n.t('drivers.title')}
          </NavLink>
        </li>
      </ul>
    );
  };

  return (
    <nav className={`navbar navbar-expand-lg navbar-light fixed-top`} id="mainNav">
      <div className="container">
        <div className='d-flex flex-row'>
          <span onClick={() => routeToHome()} className="navbar-brand js-scroll-trigger">
            <img src={logo} height="30" alt="" />
            {isFreightLocation() ? <span className='freight ml-1'>Freight</span> : null}
          </span>
          <div className="my-auto ml-2 dropdown">
            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown">
              <span>{i18n.t('general.products')}</span>
            </a>
            <div className="dropdown-menu">
              <NavLink to="/" className={({ isActive }) => "dropdown-item" + (isActive ? " active" : "")}>
                {i18n.t('general.delivery')}
              </NavLink>
              <NavLink to="/freight" className={({ isActive }) => "dropdown-item" + (isActive ? " active" : "")}>
                {i18n.t('freight.title')}
              </NavLink>
            </div>
          </div>
        </div>
        {isFreightLocation() ? null : (
          <button onClick={() => toggleShow()} className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive">
            <MenuOutline />
          </button>
        )}
        <div className={`collapse navbar-collapse ${show}`} id="navbarResponsive">
          {isFreightLocation() ? null : renderLinks()}
        </div>
      </div>
    </nav>
  );
};

export default withRouter(NavBar);
