import React from 'react';
import { useFormik } from 'formik';
import { SelectField } from '../components/Form';
import AppDownload from '../components/AppDownload/AppDownload.component';
import Testimonial from '../components/Testimonial/Testimonial.component';
import Contact from '../components/Contact/Contact.component';
import Price from '../components/Price';
import { I18nContext } from '../contexts/i18n';
import useScrollToTop from '../utils/useScrollToTop';
import personIcon from '../assets/images/man-phone.png';
import { quoteSchema } from '../services/formValidators';
import Shipment from '../services/shipment';
import { camelize } from '../utils/string-helper';

const Quote = () => {
  const { state } = React.useContext(I18nContext);
  const i18n = state.i18n;
  const [price, setPrice] = React.useState(null);
  const [showResult, setShowResult] = React.useState(false);
  const [categories, setCategories] = React.useState([]);

  React.useEffect(() => {
    Shipment.categories.then(cats => setCategories(cats));
  }, []);

  const formik = useFormik({
    initialValues: { category: '', size: '', travelType: '' },
    validationSchema: quoteSchema,
    onSubmit: values => handleQuoteSubmit(values)
  });

  const { handleSubmit, values, setFieldValue, errors, submitCount } = formik;

  const sortedCategories = () => {
    return categories.map(cat => cat.category).sort();
  };

  const setCategoryValue = (field, val) => {
    if (values.category !== val) {
      setFieldValue('size', '');
    }

    setFieldValue(field, val);
  };

  const sizeItems = (category) => {
    if (!category) return [];

    const shipmentCategory = categories.find(cat => cat.category === category);
    if (!shipmentCategory) return [];

    return shipmentCategory.sizes.map(size => {
      const itemSize = i18n.t(`quote.form.size.${size}`);
      const itemDescription = i18n.t(`quote.form.size.${size}Description`);
      return { label: `${itemSize} (${itemDescription})`, value: size };
    });
  };

  const handleQuoteSubmit = (values) => {
    Shipment.getPrice(values).then(calculatedPrice => {
      setPrice(calculatedPrice);
      setShowResult(true);
    });
  };

  const handleGetQuote = () => {
    setShowResult(false);
    formik.resetForm();
  };

  const renderForm = () => {
    return (
      <>
        <div className="section-heading text-center ml-auto mr-auto">
          <h4>{i18n.t('quote.fillForm')}</h4>
          <hr />
        </div>

        <form onSubmit={handleSubmit} className="quote-form row mt-5">
          <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <label>{`${i18n.t('quote.form.category.label')} *`}</label>
            <SelectField
              value={values.category}
              options={sortedCategories().map(cat => ({ label: i18n.t(`quote.form.category.${cat}`), value: cat }))}
              handleChange={val => setCategoryValue('category', val)}
              placeholderText={i18n.t('quote.form.category.inputTitle')}
              errors={submitCount > 0 && errors.category}
              errorMessage={errors.category}
            />
          </div>
          <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 mt-4">
            <label>{`${i18n.t('quote.form.size.label')} *`}</label>
            <SelectField
              value={values.size}
              options={sizeItems(values.category)}
              disabled={!Boolean(values.category)}
              handleChange={val => setFieldValue('size', val)}
              placeholderText={i18n.t('quote.form.size.inputTitle')}
              errors={submitCount > 0 && errors.size}
              errorMessage={errors.size}
            />
          </div>
          <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 mt-4">
            <label>{`${i18n.t('quote.form.travelType.label')} *`}</label>
            <SelectField
              value={values.travelType}
              options={[
                { label: i18n.t('quote.form.travelType.intraUrban'), value: 'intra_urban' },
                { label: i18n.t('quote.form.travelType.interUrban'), value: 'inter_urban' }
              ]}
              handleChange={val => setFieldValue('travelType', val)}
              placeholderText={i18n.t('quote.form.travelType.inputTitle')}
              errors={submitCount > 0 && errors.travelType}
              errorMessage={errors.travelType}
            />
          </div>

          <div className="col-md-6 offset-md-3 col-lg-4 offset-lg-4 text-center">
            <button
              className="btn btn-primary btn-xl mx-auto mt-4"
              type="submit"
            >
              {i18n.t('quote.form.submit')}
            </button>
          </div>
        </form>
      </>
    );
  };

  const renderResult = () => {
    return (
      <>
        <div className="section-heading text-center ml-auto mr-auto">
          <h4>{i18n.t('quote.result')}</h4>
          <hr />
        </div>

        <div className="row mt-5">
          <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <div className="row w-100 no-gutters">
              <div className="col-6">
                <span>{`${i18n.t('quote.form.category.label')}:`}</span>
              </div>
              <div className="col-6 text-right">
                <span>{i18n.t(`quote.form.category.${values.category}`)}</span>
              </div>
            </div>
          </div>
          <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 mt-2">
            <div className="row w-100 no-gutters">
              <div className="col-6">
                <span>{`${i18n.t('quote.form.size.label')}:`}</span>
              </div>
              <div className="col-6 text-right">
                <span>{i18n.t(`quote.form.size.${values.size}`)}</span>
              </div>
            </div>
          </div>
          <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 mt-2">
            <div className="row w-100 no-gutters">
              <div className="col-6">
                <span>{`${i18n.t('quote.form.travelType.label')}:`}</span>
              </div>
              <div className="col-6 text-right">
                <span>{i18n.t(`quote.form.travelType.${camelize(values.travelType)}`)}</span>
              </div>
            </div>
          </div>
          <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 mt-4">
            <hr className="w-100 full-width"/>
          </div>
          <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 mt-4">
            <div className="row w-100 no-gutters">
              <div className="col-6">
                <span className="font-weight-bold large-text">{`${i18n.t('quote.price')}:`}</span>
              </div>
              <div className="col-6 text-right">
                <Price amount={price} classes="font-weight-bold larger-text" />
              </div>
            </div>
          </div>

          <div className="col-md-6 offset-md-3 col-lg-4 offset-lg-4 text-center">
            <button
              className="btn btn-primary btn-xl mx-auto mt-5"
              onClick={() => handleGetQuote()}
            >
              {i18n.t('quote.another')}
            </button>
          </div>
        </div>
      </>
    );
  };

  useScrollToTop();

  return (
    <>
      <section className="bg-primary-gradient">
        <div className="container">
          <div className="section-heading text-center ml-auto mr-auto mt-5">
            <h2>{i18n.t('quote.header')}</h2>
          </div>
        </div>
      </section>
      <section>
        <div className="container" id="#quote">
          {showResult ? renderResult() : renderForm()}
        </div>
      </section>
      <AppDownload />
      <Testimonial />
      <Contact
        title={i18n.t('home.contact')}
        buttonTitle={i18n.t('contactUs.title')}
        link='/contact-us'
        image={personIcon}
      />
    </>
  );
};

export default Quote;
