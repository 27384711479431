import React from 'react';
import { useNavigate } from 'react-router-dom';

import './cards.style.scss';
import { withRouter } from '../../utils/withRouter';

const backgrounds = ['card-background-primary-dark', 'card-background-primary', 'card-background-primary-light']
const Cards = (props) => {
  const navigate = useNavigate();
  return (
    <ul className='cards d-flex flex-wrap justify-content-center'>
      {props.items.map((item, index) => {
        return (
          <li
            key={index}
            className={`card ${backgrounds[index]} ${props.link ? 'link' : ''}`}
            onClick={() => props.link ? navigate(item.path) : null}
          >
            {item.icon ? <item.icon className="icon"/> : null}
            <div className="card-body">
              <h5 className="card-title title">{item.title}</h5>
              <p className="card-text">{item.text}</p>
              <h6 className="footer">{item.footer}</h6>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default withRouter(Cards);
