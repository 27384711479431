import axios from 'axios';
import { decamelize, camelize } from '../utils/string-helper';

export default class ApiBase {
  constructor() {
    this.httpClient = axios;
  }

  get apiHostUrl() {
    return `${process.env.REACT_APP_URL}/api`;
  }

  get apiToken() {
    return process.env.REACT_APP_BE_TOKEN;
  }

  decamelizeAttributes(attributes) {
    return Object.keys(attributes).reduce((acc, attr) => {
      acc[decamelize(attr)] = attributes[attr];
      return acc;
    }, {});
  }

  camelizeAttributes(attributes) {
    return Object.keys(attributes).reduce((acc, attr) => {
      acc[camelize(attr)] = attributes[attr];
      return acc;
    }, {});
  }
}
