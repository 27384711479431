import ApiBase from './apiBase';

export default class Contact extends ApiBase {
  static send(attributes) {
    const myself = new Contact();
    return myself.httpClient.post(
      `${myself.apiHostUrl}/contacts`,
      { contact: myself.decamelizeAttributes(attributes) },
      { headers: { 'Authorization': myself.apiToken } }
    ).then(response => response.data.data);
  }
};
