import React from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";

import './App.scss';
import NavBar from './components/NavBar/NavBar.component';
import Home from './containers/Home';
import FreightHome from './containers/freight/Home';
import SmallBusiness from './containers/SmallBusiness';
import ArtisanFoods from './containers/SmallBusiness/ArtisanFoods';
import Automotive from './containers/SmallBusiness/Automotive';
import Ecommerce from './containers/SmallBusiness/Ecommerce';
import HeavyEquipment from './containers/SmallBusiness/HeavyEquipment';
import HomeDecor from './containers/SmallBusiness/HomeDecor';
import PharmacyDelivery from './containers/SmallBusiness/PharmacyDelivery';
import Personal from './containers/Personal';
import BulkItems from './containers/Personal/BulkItems';
import ConstructionEquipment from './containers/Personal/ConstructionEquipment';
import Furniture from './containers/Personal/Furniture';
import SocialMediaRetailers from './containers/Personal/SocialMediaRetailers';
import Drivers from './containers/Drivers';
import AboutUs from './containers/AboutUs';
import ContactUs from './containers/ContactUs';
import Faqs from './containers/Faqs';
import PrivacyPolicy from './containers/PrivacyPolicy';
import TermsAndConditions from './containers/TermsAndConditions';
import Quote from './containers/Quote';
import Footer from './components/Footer/Footer.component';

import { I18nContext, initialState, reducer } from './contexts/i18n';
import constants from './Constants';
import { useGaRouteTracker } from './useGaTracker';

const AndroidAppUrl = () => {
  React.useEffect(() => {
    window.location.assign(constants.APP_GOOGLE_PLAYSTORE_URL);
  }, []);

  return null;
}

const AppContent = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  useGaRouteTracker();

  return (
    <I18nContext.Provider value={{ state, dispatch }}>
      <NavBar/>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/small-business' element={<SmallBusiness />} />
        <Route exact path='/small-business/artisan-foods' element={<ArtisanFoods />} />
        <Route exact path='/small-business/hardware-equipment' element={<Automotive />} />
        <Route exact path='/small-business/ecommerce' element={<Ecommerce />} />
        <Route exact path='/small-business/heavy-equipment' element={<HeavyEquipment />} />
        <Route exact path='/small-business/home-decor' element={<HomeDecor />} />
        <Route exact path='/small-business/pharmacy-delivery' element={<PharmacyDelivery />} />
        <Route exact path='/personal' element={<Personal />} />
        <Route exact path='/personal/bulk-items' element={<BulkItems />} />
        <Route exact path='/personal/construction-equipment' element={<ConstructionEquipment />} />
        <Route exact path='/personal/furniture' element={<Furniture />} />
        <Route exact path='/personal/social-media-retailers' element={<SocialMediaRetailers />} />
        <Route exact path='/drivers' element={<Drivers />} />
        <Route exact path='/about-us' element={<AboutUs />} />
        <Route exact path='/contact-us' element={<ContactUs />} />
        <Route exact path='/download/android' element={<AndroidAppUrl />} />
        <Route exact path='/freight' element={<FreightHome />} />
        <Route path='/privacy' element={<PrivacyPolicy />} />
        <Route path='/terms' element={<TermsAndConditions />} />
        <Route path='/faqs' element={<Faqs />} />
        <Route path='/quote' element={<Quote />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
      <Footer />
    </I18nContext.Provider>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
