import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

export const useGaRouteTracker = () => {
  const location = useLocation();

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
      if (process.env.REACT_APP_ENV === 'production') {
        ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NUMBER);
        setInitialized(true);
      }
  }, []);

  useEffect(() => {
      if (initialized) {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname + location.search);
      }
  }, [initialized, location]);
};

export const sendAppClickEvent = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    ReactGA.event({
      category: 'App Download Link',
      action: 'User pressed the app download button'
    });
  }
};
