import React from 'react';

const Price = (props) => {
  return (
    <span className={props.classes}>
      <span>{props.amount}</span>
      <sup>XAF</sup>
    </span>
  );
};

export default Price;
