import React from 'react';
import { NavLink } from "react-router-dom";
import { LogoFacebook } from 'react-ionicons';
import { LogoWhatsapp } from 'react-ionicons';
import { LogoInstagram } from 'react-ionicons';

import { withRouter } from '../../utils/withRouter';
import { I18nContext } from '../../contexts/i18n';
import { EN, FR } from '../../i18n';
import constants from '../../Constants';
import { phoneFormat } from '../../utils/string-helper';
import './footer.style.scss';

const Footer = (props) => {
  const { state, dispatch } = React.useContext(I18nContext);
  const i18n = state.i18n;

  const changeLanguage = (lang) => dispatch(lang === EN ? 'ENGLISH' : 'FRENCH');

  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="links d-flex flex-column flex-md-row justify-content-center justify-content-md-start">
                <NavLink to="/about-us" className={({ isActive }) => "footer-link mx-auto mx-md-0" + (isActive ? " active" : "")}>{i18n.t('aboutUs.title')}</NavLink>
                <NavLink to="/contact-us" className={({ isActive }) => "footer-link mx-auto mx-md-0" + (isActive ? " active" : "")}>{i18n.t('contactUs.title')}</NavLink>
              </div>
            </div>
            <div className="col-md-4">
              <div className="language mt-3 mt-md-0 d-flex flex-row justify-content-center justify-content-md-end">
                <span className={`footer-link ${i18n.locale === EN ? 'active' : ''}`} onClick={() => changeLanguage(EN)}>{i18n.t(`lang.${EN}`)}</span>
                <span className={`footer-link ${i18n.locale === FR ? 'active' : ''}`} onClick={() => changeLanguage(FR)}>{i18n.t(`lang.${FR}`)}</span>
              </div>
            </div>
          </div>
          <div className="mt-5 social d-flex justify-content-center">
            <a href="https://www.facebook.com/NginaApp" target="_blank" rel="noopener noreferrer" className="mx-2">
              <LogoFacebook/>
            </a>
            <a href="https://www.instagram.com/ngina_app" target="_blank" rel="noopener noreferrer" className="mx-2">
              <LogoInstagram/>
            </a>
            <a href="#" data-toggle="modal" data-target="#whatsappModal" className="mx-2">
              <LogoWhatsapp/>
            </a>
          </div>
          <p className="mt-3">&copy; {currentYear} Ngina. {i18n.t('general.rights')}</p>
          <ul className="list-inline">
            <li className="list-inline-item">
              <NavLink to="/privacy">{i18n.t('general.privacyLink')}</NavLink>
            </li>
            <li className="list-inline-item">
              <NavLink to="/terms">{i18n.t('general.termsLink')}</NavLink>
            </li>
            <li className="list-inline-item">
              <NavLink to="/faqs">{i18n.t('general.faqs.abbreviation')}</NavLink>
            </li>
          </ul>
        </div>
      </footer>
      <div className="modal fade" id="whatsappModal" tabIndex="-1" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body text-center my-2">
              {`${i18n.t('general.whatsappContact')}: ${phoneFormat(constants.NGINA_SUPPORT_PHONE_NUMBER)}`}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Footer);
