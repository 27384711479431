import React from 'react';
import { NavLink } from 'react-router-dom';
import { Image, CreditCard } from 'react-feather';
import { DocumentTextOutline, ShareSocialOutline } from 'react-ionicons';

import Header from '../../components/Header/Header.component';
import AppDownload from '../../components/AppDownload/AppDownload.component';
import Contact from '../../components/Contact/Contact.component';
import FeatureItem from '../../components/FeatureItem/FeatureItem.component';
import { I18nContext } from '../../contexts/i18n';
import useScrollToTop from '../../utils/useScrollToTop';
import { black } from '../../assets/colors';
import personImage from '../../assets/images/man-phone.png';
import trucks from  '../../assets/images/trucks.jpg';
import van from '../../assets/images/trucks/van.png';
import closedTruck from '../../assets/images/trucks/closed-truck.png';
import flatbedTruck from '../../assets/images/trucks/flatbed-truck.png';
import dumpTruck from '../../assets/images/trucks/dump-truck.png';


const Home = () => {
  const { state } = React.useContext(I18nContext);
  const i18n = state.i18n;
  useScrollToTop();

  return (
    <>
      <Header
        title={i18n.t('freight.headerTitle')}
        body={i18n.t('freight.headerBody')}
      />
      <section className="note-section shift-up">
        <div className="container">
          <div className="section-heading text-center ml-auto mr-auto">
            <h2>{i18n.t('freight.optimize.title')}</h2>
            <p className="text-muted">{i18n.t('freight.optimize.text1')}</p>
            <p className="text-muted">{i18n.t('freight.optimize.text2')}</p>

          </div>
        </div>
      </section>
      <section className='pt-5'>
        <div className='container'>
          <h2 className='text-center'>{i18n.t('freight.truck.supportedTitle')}</h2>
          <hr className='mb-5' />
          <div className="row">
            <div className="col-md-6">
              <FeatureItem
                imageSrc={van}
                subtitle={i18n.t('freight.truck.van.title')}
                text={i18n.t('freight.truck.van.text')}
                classNames='text-center'
              />
            </div>
            <div className="col-md-6">
              <FeatureItem
                imageSrc={closedTruck}
                subtitle={i18n.t('freight.truck.closed.title')}
                text={i18n.t('freight.truck.closed.text')}
                classNames='text-center'
              />
            </div>
            <div className="col-md-6">
              <FeatureItem
                imageSrc={dumpTruck}
                subtitle={i18n.t('freight.truck.dump.title')}
                text={i18n.t('freight.truck.dump.text')}
                classNames='text-center'
              />
            </div>
            <div className="col-md-6">
              <FeatureItem
                imageSrc={flatbedTruck}
                subtitle={i18n.t('freight.truck.flatbed.title')}
                text={i18n.t('freight.truck.flatbed.text')}
                classNames='text-center'
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-grey">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>{i18n.t('freight.trucker.title')}</h2>
              <p className='mt-3'>{i18n.t('freight.trucker.text1')}</p>
              <p>{i18n.t('freight.trucker.text2')}</p>
              <NavLink to='/download/android' className="btn btn-outline reverse btn-xl my-3 mx-auto ml-lg-0">
                {i18n.t('general.registerToday')}
              </NavLink>
            </div>
            <div className="col-md-6">
              <img src={trucks} className="img-fluid w-100 mb-3" alt="" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mt-5 text-center">
            <h3>{i18n.t('freight.trucker.requirements.title')}</h3>
          </div>
          <p className="mt-4">{i18n.t('freight.trucker.requirements.text')}</p>
          <div className="row">
            {[
              { icon: CreditCard, text: i18n.t('freight.trucker.requirements.item1')},
              { icon: CreditCard, text: i18n.t('freight.trucker.requirements.item2')},
              { icon: Image, text: i18n.t('freight.trucker.requirements.item3')},
              { icon: Image, text: i18n.t('freight.trucker.requirements.item4')}
            ].map((item, index) => (
              <div className="col-lg-6" key={index}>
                <item.icon color={black} />
                <span className="pl-2">{item.text}</span>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section>
        <div className='container text-center'>
          <h2>{i18n.t('freight.sender.title')}</h2>
          <p>{i18n.t('freight.sender.text')}</p>
          <div className='row mt-5'>
            <div className='col-md-6'>
              <FeatureItem
                icon={DocumentTextOutline}
                subtitle={i18n.t('freight.sender.texts.title1')}
                text={i18n.t('freight.sender.texts.text1')}
              />
            </div>
            <div className='col-md-6'>
              <FeatureItem
                icon={ShareSocialOutline}
                subtitle={i18n.t('freight.sender.texts.title2')}
                text={i18n.t('freight.sender.texts.text2')}
              />
            </div>
          </div>
        </div>
      </section>
      <AppDownload />
      <Contact
        title={i18n.t('home.contact')}
        buttonTitle={i18n.t('contactUs.title')}
        link='/contact-us'
        image={personImage}
      />
    </>
  );
};

export default Home;
