import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';

/*
 * Registers a history listener on mount which
 * scrolls to the top of the page on route change
 */
const useScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    const element = document.getElementById(location.hash);

    scroll.scrollTo(element ? element.offsetTop : 0);
  }, [location.pathname]);

  return null;
};

export default useScrollToTop;
