import React from 'react';
import './about-customer.style.scss';
import FeatureItem from '../FeatureItem/FeatureItem.component';

const AboutShipper = (props) => {
  return (
    <section className="bg-grey" id="about_customer">
      <div className="note-section">
        <div className="container-fluid">
          <div className="section-heading text-center ml-auto mr-auto">
            <h2>{props.title}</h2>
            {props.text ? <p>{props.text}</p> : null}
            <hr />
          </div>
          <div className="row mt-5">
            {props.texts.map((reason, index) => {
              return (
                <div className="col-lg-4" key={index}>
                  <FeatureItem
                    title={reason.title}
                    text={reason.text}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutShipper;
