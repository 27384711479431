import React from 'react';
import ReactMarkdown from 'react-markdown';

import { I18nContext } from '../contexts/i18n';
import { EN, FR, termsEn, termsFr } from '../i18n';
import useScrollToTop from '../utils/useScrollToTop';

const TermsAndConditions = () => {
  const { state } = React.useContext(I18nContext);
  const i18n = state.i18n;

  const [terms, setTerms] = React.useState('');

  React.useEffect(() => {
    if (i18n.locale === EN) {
      fetch(termsEn).then((response) => response.text()).then(text => setTerms(text));
    }

    if (i18n.locale === FR) {
      fetch(termsFr).then((response) => response.text()).then(text => setTerms(text));
    }
  }, [i18n.locale]);

  useScrollToTop();

  return (
    <>
      <section className="bg-primary-gradient">
        <div className="container">
          <div className="section-heading text-center ml-auto mr-auto mt-5">
            <h2>{i18n.t('general.terms')}</h2>
          </div>
        </div>
      </section>
      <section>
        <div className="container h3-center">
          <ReactMarkdown
            source={terms}
            linkTarget='_blank'
          />
        </div>
      </section>
    </>
  );
};

export default TermsAndConditions;
