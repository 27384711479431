import React from 'react';
import PhoneInput from 'react-phone-input-2'

const PhoneInputField = (props) => {
  const renderError = () => {
    if (!props.errors) return null;

    return <div className="invalid-feedback show">{props.errorMessage}</div>;
  };

  return (
    <>
      <PhoneInput
        country={props.defaultCountry || 'cm'}
        value={props.value}
        disableDropdown={props.countrySelectDisabled || false}
        placeholder={props.placeholderText}
        countryCodeEditable={false}
        localization={false}
        onChange={phone => props.handleChange(phone)}
        inputProps={{ className: `w-100 form-control ${props.errors ? 'is-invalid' : ''}` }}
      />
      {renderError()}
    </>
  );
};

export default PhoneInputField;
