import React from 'react';

const StringInputField = (props) => {
  const renderError = () => {
    if (!props.errorMessage) return null;

    return <div className="invalid-feedback">{props.errorMessage}</div>;
  };

  return (
    <>
      <input
        id={props.fieldName}
        name={props.fieldName}
        type='text'
        placeholder={props.placeholderText}
        onChange={event => props.handleChange(event.target.value)}
        onBlur={props.handleBlur}
        value={props.value}
        className={`form-control ${props.errors ? 'is-invalid' : ''}`}
      />
      {renderError()}
    </>
  );
};

export default StringInputField;
