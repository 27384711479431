import React from 'react';
import './header.style.scss';
import '../../assets/device-mockups/device-mockups.css';
import { I18nContext } from '../../contexts/i18n';
import demoScreen from '../../assets/images/demo-screen.jpg';

const Header = (props) => {
  const { state } = React.useContext(I18nContext);
  const i18n = state.i18n;

  return (
    <header className="masthead">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-7 my-auto">
            <div className="header-content mx-auto">
              {props.tag ? <div className="tag">{props.tag}</div> : null}
              <h1>{props.title}</h1>
              {props.body ? <p>{props.body}</p> : null}
              <a href="#download" className="btn btn-outline btn-xl js-scroll-trigger">{i18n.t('general.startNow')}</a>
            </div>
          </div>
          <div className="col-lg-5 my-auto">
            <div className="header-device-container">
              <div className="device-mockup pixel_3a portrait">
                <div className="device">
                  <div className="screen">
                    <img src={demoScreen} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
