import React from 'react';
import ReactMarkdown from 'react-markdown';

import { withRouter } from '../../utils/withRouter';
import SmallBusinessSubNav from '../../components/SmallBusinessSubNav';
import AppDownload from '../../components/AppDownload/AppDownload.component';
import { I18nContext } from '../../contexts/i18n';
import useScrollToTop from '../../utils/useScrollToTop';
import heavyEquipment from  '../../assets/images/heavy-equipment.jpg';

const HeavyEquipment = (props) => {
  const { state } = React.useContext(I18nContext);
  const i18n = state.i18n;

  useScrollToTop();

  return (
    <>
      <SmallBusinessSubNav classNames='mt-5 pt-2' />
      <section>
        <div className="container">
          <div className="row section-heading">
            <div className="col-lg-8 my-auto">
              <div className="tag">{i18n.t('sender.smallBusiness.heavyEquipment.label')}</div>
              <h1>{i18n.t('sender.smallBusiness.heavyEquipment.headerTitle')}</h1>
              <a href="#download" className="btn btn-primary btn-xl js-scroll-trigger">{i18n.t('general.joinAndSend')}</a>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-grey">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img src={heavyEquipment} className="img-fluid w-100 mb-3" alt="" />
              <h5 className="font-weight-bold">{i18n.t('sender.smallBusiness.heavyEquipment.heading1')}</h5>
              <p>{i18n.t('sender.smallBusiness.heavyEquipment.text1')}</p>
              <h5 className="font-weight-bold">{i18n.t('sender.smallBusiness.heavyEquipment.heading2')}</h5>
              <p>{i18n.t('sender.smallBusiness.heavyEquipment.text2')}</p>
              <h5 className="font-weight-bold">{i18n.t('sender.smallBusiness.heavyEquipment.heading3')}</h5>
              <p>{i18n.t('sender.smallBusiness.heavyEquipment.text3')}</p>
            </div>
            <div className="col-md-6">
              <h5 className="font-weight-bold">{i18n.t('sender.smallBusiness.heavyEquipment.heading4')}</h5>
              <p>{i18n.t('sender.smallBusiness.heavyEquipment.text4')}</p>
              <h5 className="font-weight-bold">{i18n.t('sender.smallBusiness.heavyEquipment.heading5')}</h5>
              <ul>
                <li><ReactMarkdown renderers={{ paragraph: 'span' }} source={i18n.t('sender.smallBusiness.heavyEquipment.point51')} /></li>
                <li><ReactMarkdown renderers={{ paragraph: 'span' }} source={i18n.t('sender.smallBusiness.heavyEquipment.point52')} /></li>
                <li><ReactMarkdown renderers={{ paragraph: 'span' }} source={i18n.t('sender.smallBusiness.heavyEquipment.point53')} /></li>
                <li><ReactMarkdown renderers={{ paragraph: 'span' }} source={i18n.t('sender.smallBusiness.heavyEquipment.point54')} /></li>
                <li><ReactMarkdown renderers={{ paragraph: 'span' }} source={i18n.t('sender.smallBusiness.heavyEquipment.point55')} /></li>
              </ul>
              <h5 className="font-weight-bold">{i18n.t('sender.smallBusiness.heavyEquipment.heading6')}</h5>
              <p>{i18n.t('sender.smallBusiness.heavyEquipment.text6')}</p>
            </div>
          </div>
        </div>
      </section>
      <AppDownload />
    </>
  );
};

export default withRouter(HeavyEquipment);
