import React from 'react';
import ReactMarkdown from 'react-markdown';

import { withRouter } from '../../utils/withRouter';
import PersonalSubNav from '../../components/PersonalSubNav';
import AppDownload from '../../components/AppDownload/AppDownload.component';
import { I18nContext } from '../../contexts/i18n';
import useScrollToTop from '../../utils/useScrollToTop';
import socialMediaRetailers from  '../../assets/images/social-media-retailers.jpg';

const SocialMediaRetailers = (props) => {
  const { state } = React.useContext(I18nContext);
  const i18n = state.i18n;

  useScrollToTop();

  return (
    <>
      <PersonalSubNav classNames='mt-5 pt-2' />
      <section>
        <div className="container">
          <div className="row section-heading">
            <div className="col-lg-8 my-auto">
              <div className="tag">{i18n.t('sender.personal.socialMediaRetailers.label')}</div>
              <h1>{i18n.t('sender.personal.socialMediaRetailers.headerTitle')}</h1>
              <a href="#download" className="btn btn-primary btn-xl js-scroll-trigger">{i18n.t('general.sendNow')}</a>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-grey">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img src={socialMediaRetailers} className="img-fluid w-100 mb-3" alt="" />
              <p><ReactMarkdown source={i18n.t('sender.personal.socialMediaRetailers.text1')} /></p>
            </div>
            <div className="col-md-6">
              <h5 className="font-weight-bold">{i18n.t('sender.personal.socialMediaRetailers.heading2')}</h5>
              <p>{i18n.t('sender.personal.socialMediaRetailers.text2')}</p>
              <h5 className="font-weight-bold">{i18n.t('sender.personal.socialMediaRetailers.heading3')}</h5>
              <p>{i18n.t('sender.personal.socialMediaRetailers.text3')}</p>
            </div>
          </div>
        </div>
      </section>
      <AppDownload />
    </>
  );
};

export default withRouter(SocialMediaRetailers);
